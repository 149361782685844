import React from 'react';
import { bool, func, string } from 'prop-types';
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerBody,
  DrawerCloseButton,
  DrawerHeader,
  Center,
  Box,
} from '@chakra-ui/react';

import useLang from '@/hooks/useLang';
import useInvitation from '@/hooks/useInvitation';

import storyData from '@/api-data/love-story';
import LoveStorySwapable from '@/components/Common/LoveStorySwipe';

import { BUTTON_PROPS } from '@/constants/colors';

import txtWording from './locales';

function LoveStoryContent({ onClose, visible }) {
  const invitation = useInvitation();
  const lang = useLang();

  return (
    <Drawer size="full" onClose={onClose} isOpen={visible}>
      <DrawerOverlay />
      <DrawerContent maxW="500px" bgColor="bgPrimary" bgSize="cover">
        <DrawerCloseButton marginTop="12px" display="none" />
        <DrawerHeader />
        <DrawerBody>
          <LoveStorySwapable story={storyData} />
          <Center>
            <Button
              margin="16px 0 32px 0"
              letterSpacing="2px"
              textTransform="uppercase"
              onClick={onClose}
              bgColor="bgAlternative"
              color="white"
              size="sm"
              fontWeight="normal"
              marginTop="16px"
              {...BUTTON_PROPS}
            >
              {invitation
                ? txtWording.invitationBack[lang]
                : txtWording.back[lang]
              }
            </Button>
          </Center>
          <Box height="30px" />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

LoveStoryContent.propTypes = {
  lang: string,
  visible: bool.isRequired,
  onClose: func.isRequired,
};

LoveStoryContent.defaultProps = {
  lang: 'id',
};

export default LoveStoryContent;